export default {
  "about": {
    "bubble_instances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Bubble Instances"])},
    "bubble_instances_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instances chosen by the admins to represent the local area of this instance"])},
    "mrf": {
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federation"])},
      "keyword": {
        "ftl_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removal from \"The Whole Known Network\" Timeline"])},
        "is_replaced_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["→"])},
        "keyword_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword policies"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])}
      },
      "mrf_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled MRF policies"])},
      "mrf_policies_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRF policies manipulate the federation behaviour of the instance.  The following policies are enabled:"])},
      "simple": {
        "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "accept_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance only accepts messages from the following instances:"])},
        "ftl_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removal from \"Known Network\" Timeline"])},
        "ftl_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance removes these instances from \"Known Network\" timeline:"])},
        "instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance"])},
        "media_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media force-set as sensitive"])},
        "media_nsfw_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance forces media to be set sensitive in posts on the following instances:"])},
        "media_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Removal"])},
        "media_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance removes media from posts on the following instances:"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine"])},
        "quarantine_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance will not send posts to the following instances:"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "reject_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance will not accept messages from the following instances:"])},
        "simple_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance-specific policies"])}
      }
    },
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])}
  },
  "announcements": {
    "all_day_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an all-day event"])},
    "cancel_edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "delete_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "end_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ends at ", _interpolate(_named("time"))])},
    "end_time_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time: "])},
    "inactive_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This announcement is inactive"])},
    "mark_as_read_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
    "page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "post_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "post_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
    "post_form_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post announcement"])},
    "post_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement content"])},
    "published_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Published at ", _interpolate(_named("time"))])},
    "start_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starts at ", _interpolate(_named("time"))])},
    "start_time_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time: "])},
    "submit_edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])}
  },
  "chats": {
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this message?"])},
    "empty_chat_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any chats yet. Start a new chat!"])},
    "empty_message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot post empty message"])},
    "error_loading_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when loading the chat."])},
    "error_sending_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when sending the message."])},
    "message_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Message ", _interpolate(_named("nickname"))])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Chat"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You:"])}
  },
  "display_date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])}
  },
  "domain_mute_card": {
    "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
    "mute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muting…"])},
    "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute"])},
    "unmute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmuting…"])}
  },
  "emoji": {
    "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert emoji"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom emoji"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
    "keep_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep picker open"])},
    "load_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loading all ", _interpolate(_named("emojiAmount")), " emoji"])},
    "load_all_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loaded first ", _interpolate(_named("saneAmount")), " emoji, loading all emoji may cause performance issues."])},
    "search_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an emoji"])},
    "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stickers"])},
    "unicode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unicode emoji"])},
    "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently used"])}
  },
  "errors": {
    "storage_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleroma could not access browser storage. Your login or your local settings won't be saved and you might encounter unexpected issues. Try enabling cookies."])}
  },
  "exporter": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing, you'll soon be asked to download your file"])}
  },
  "features_panel": {
    "media_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media proxy"])},
    "scope_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope options"])},
    "text_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text limit"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "upload_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload limit"])},
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])}
  },
  "file_type": {
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])}
  },
  "finder": {
    "error_fetching_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching user"])},
    "find_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find user"])}
  },
  "general": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "error_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "flash_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show Flash content using Ruffle (Experimental, may not work)."])},
    "flash_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load flash content, see console for details."])},
    "flash_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that this can be potentially dangerous since Flash content is still arbitrary code."])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading…"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
    "peek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peek"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "role": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator"])}
    },
    "scope_in_timeline": {
      "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local - only your instance can see this post"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers-only"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlisted"])}
    },
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])}
  },
  "image_cropper": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "crop_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crop picture"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "save_without_cropping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save without cropping"])}
  },
  "importer": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while importing this file."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported successfully."])}
  },
  "interactions": {
    "favs_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats and favorites"])},
    "follows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New follows"])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older interactions"])},
    "moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User migrates"])}
  },
  "languages": {
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijani"])},
    "bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danish"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "eo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperanto"])},
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persian"])},
    "fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnish"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irish"])},
    "he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuanian"])},
    "lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "translated_from": {
      "ar": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.ar", undefined, _type)])},
      "az": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.az", undefined, _type)])},
      "bg": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.bg", undefined, _type)])},
      "cs": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.cs", undefined, _type)])},
      "da": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.da", undefined, _type)])},
      "de": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.de", undefined, _type)])},
      "el": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.el", undefined, _type)])},
      "en": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.en", undefined, _type)])},
      "eo": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.eo", undefined, _type)])},
      "es": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.es", undefined, _type)])},
      "fa": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.fa", undefined, _type)])},
      "fi": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.fi", undefined, _type)])},
      "fr": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.fr", undefined, _type)])},
      "ga": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.ga", undefined, _type)])},
      "he": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.he", undefined, _type)])},
      "hi": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.hi", undefined, _type)])},
      "hu": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.hu", undefined, _type)])},
      "id": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.id", undefined, _type)])},
      "it": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.it", undefined, _type)])},
      "ja": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.ja", undefined, _type)])},
      "ko": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.ko", undefined, _type)])},
      "lt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.lt", undefined, _type)])},
      "lv": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.lv", undefined, _type)])},
      "nl": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.nl", undefined, _type)])},
      "pl": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.pl", undefined, _type)])},
      "pt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.pt", undefined, _type)])},
      "ru": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.ru", undefined, _type)])},
      "sk": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.sk", undefined, _type)])},
      "sv": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.sv", undefined, _type)])},
      "tr": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.tr", undefined, _type)])},
      "uk": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.uk", undefined, _type)])},
      "zh": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Translated from ", _linked("languages.zh", undefined, _type)])}
    },
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])}
  },
  "lists": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete list"])},
    "following_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit to Following"])},
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New List"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search users"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List title"])}
  },
  "login": {
    "authentication_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with OAuth"])},
    "enter_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a recovery code"])},
    "enter_two_factor_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a two-factor code"])},
    "heading": {
      "recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor recovery"])},
      "totp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])}
    },
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to join the discussion"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "logout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
    "logout_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "logout_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "logout_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myusername"])},
    "recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery code"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
  },
  "media_modal": {
    "counter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("current")), " / ", _interpolate(_named("total"))])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close media viewer"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
  },
  "moderation": {
    "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
    "reports": {
      "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "delete_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "delete_note_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
      "delete_note_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, keep it"])},
      "delete_note_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this note?"])},
      "delete_note_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion"])},
      "no_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No description given"])},
      "no_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reports to show"])},
      "note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a note"])},
      "notes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " note"]), _normalize([_interpolate(_named("count")), " notes"])])},
      "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report on"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve"])},
      "show_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show closed"])},
      "statuses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " post"]), _normalize([_interpolate(_named("count")), " posts"])])},
      "tag_policy_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable the TagPolicy MRF to set post restrictions"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set post restrictions"])}
    },
    "statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
    "bubble_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble timeline"])},
    "bubble_timeline_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts from instances close to yours, as recommended by the admins"])},
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct messages"])},
    "friend_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow requests"])},
    "home_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home timeline"])},
    "home_timeline_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts from people you follow"])},
    "interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactions"])},
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
    "public_timeline_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public posts from this instance"])},
    "public_tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public timeline"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
    "timelines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timelines"])},
    "twkn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known Network"])},
    "twkn_timeline_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts from the entire network"])},
    "user_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Search"])},
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])}
  },
  "notifications": {
    "broken_favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown post, searching for it…"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error fetching notifications: ", _interpolate(_list(0))])},
    "favorited_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorited your post"])},
    "follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wants to follow you"])},
    "followed_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["followed you"])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older notifications"])},
    "migrated_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["migrated to"])},
    "no_more_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more notifications"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "poll_ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll has ended"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["reacted with ", _interpolate(_list(0))])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read!"])},
    "repeated_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeated your post"])}
  },
  "password_reset": {
    "check_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email for a link to reset your password."])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address or username. We will send you a link to reset your password."])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
    "password_reset_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset is disabled. Please contact your instance administrator."])},
    "password_reset_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must reset your password to log in."])},
    "password_reset_required_but_mailer_is_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must reset your password, but password reset is disabled. Please contact your instance administrator."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or username"])},
    "return_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the home page"])},
    "too_many_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit of attempts, try again later."])}
  },
  "polls": {
    "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])},
    "add_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add poll"])},
    "expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poll ended ", _interpolate(_list(0)), " ago"])},
    "expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poll ends in ", _interpolate(_list(0))])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll age"])},
    "multiple_choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple choices"])},
    "not_enough_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few unique options in poll"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "people_voted_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " person voted"]), _normalize([_interpolate(_named("count")), " people voted"])])},
    "single_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single choice"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll type"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote"])},
    "votes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votes"])},
    "votes_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " vote"]), _normalize([_interpolate(_named("count")), " votes"])])}
  },
  "post_status": {
    "account_not_locked_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your account is not ", _interpolate(_list(0)), ". Anyone can follow you to view your follower-only posts."])},
    "account_not_locked_warning_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locked"])},
    "attachments_sensitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark attachments as sensitive"])},
    "content_type": {
      "text/bbcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BBCode"])},
      "text/html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
      "text/markdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markdown"])},
      "text/plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain text"])},
      "text/x.misskeymarkdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MFM"])}
    },
    "content_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Warning (optional)"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just arrived at Luna Nova Academy"])},
    "direct_warning_to_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to all the mentioned users."])},
    "direct_warning_to_first_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will only be visible to the mentioned users at the beginning of the message."])},
    "edit_remote_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes made to the post may not be visible on some instances!"])},
    "edit_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Status"])},
    "edit_unsupported_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polls and mentions will not be changed by editing."])},
    "empty_status_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't send a post with no content and no files"])},
    "media_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media description"])},
    "media_description_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update media, try again"])},
    "media_not_sensitive_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a Content Warning, but the attachments are not marked as sensitive!"])},
    "new_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New post"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "posting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "preview_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
    "scope": {
      "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct - post to mentioned users only"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local - do not federate this post"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers-only - post to followers only"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public - post to public timelines"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlisted - do not post to public timelines"])}
    },
    "scope_notice": {
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will not be visible on other instances"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to your followers only"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to everyone"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will not be visible in Public Timeline and The Whole Known Network"])}
    }
  },
  "registration": {
    "awaiting_email_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been registered and an email has been sent to your address. Please check the email to complete registration."])},
    "awaiting_email_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting email confirmation"])},
    "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "bio_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g.\nHi! Welcome to my bio.\nI love watching anime and playing games. I hope we can be friends!"])},
    "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which language do you want to receive emails from the server?"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
    "fullname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Atsuko Kagari"])},
    "new_captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the image to get a new captcha"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason to register"])},
    "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance approves registrations manually.\nLet the administration know why you want to register."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "request_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration request has been sent for approval. You will receive an email when your account is approved."])},
    "request_sent_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration request sent"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite token"])},
    "username_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. akko"])},
    "validations": {
      "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "fullname_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "password_confirmation_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["should be the same as password"])},
      "password_confirmation_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])}
    }
  },
  "remote_user_resolver": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found."])},
    "remote_user_resolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote user resolver"])},
    "searching_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for"])}
  },
  "search": {
    "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
    "people_talking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " people talking"])},
    "person_talking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " person talking"])}
  },
  "selectable_list": {
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])}
  },
  "settings": {
    "accent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent"])},
    "account_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aliases"])},
    "account_alias_table_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
    "account_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account backup"])},
    "account_backup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This allows you to download an archive of your account information and your posts, but they cannot yet be imported into a Pleroma account."])},
    "account_backup_table_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
    "account_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "add_alias_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error adding alias: ", _interpolate(_named("error"))])},
    "add_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new backup"])},
    "add_backup_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error adding a new backup: ", _interpolate(_named("error"))])},
    "added_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias is added."])},
    "added_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a new backup."])},
    "allow_following_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow auto-follow when following account moves"])},
    "always_show_post_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always show floating New Post button"])},
    "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])},
    "attachmentRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "autohide_floating_post_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically hide New Post button (mobile)"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
    "avatarAltRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars (notifications)"])},
    "avatarRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "avatar_size_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The recommended minimum size for avatar images is 150x150 pixels."])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
    "backup_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This backup is not ready yet."])},
    "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "block_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block export"])},
    "block_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your blocks to a csv file"])},
    "block_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block import"])},
    "block_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing blocks"])},
    "blocks_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks imported! Processing them will take a while."])},
    "blocks_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a bot account"])},
    "btnRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "cBlue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue (Reply, follow)"])},
    "cGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green (Retweet)"])},
    "cOrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange (Favorite)"])},
    "cRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red (Cancel)"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email"])},
    "change_email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue changing your email."])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "change_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue changing your password."])},
    "changed_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email changed successfully!"])},
    "changed_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully!"])},
    "chatMessageRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat message"])},
    "checkboxRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkboxes"])},
    "collapse_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse posts with content warnings"])},
    "columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
    "composing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composing"])},
    "confirm_dialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Require confirmation for:"])},
    "confirm_dialogs_approve_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepting a follow request"])},
    "confirm_dialogs_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking someone"])},
    "confirm_dialogs_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a post"])},
    "confirm_dialogs_deny_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejecting a follow request"])},
    "confirm_dialogs_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muting someone"])},
    "confirm_dialogs_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeating a post"])},
    "confirm_dialogs_unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollowing someone"])},
    "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "confirmation_dialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation options"])},
    "conversation_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation display style"])},
    "conversation_display_linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear-style"])},
    "conversation_display_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree-style"])},
    "conversation_other_replies_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the \"other replies\" button"])},
    "conversation_other_replies_button_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below posts"])},
    "conversation_other_replies_button_inside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside posts"])},
    "current_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current avatar"])},
    "current_mascot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current mascot"])},
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "data_import_export_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data import / export"])},
    "default_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default visibility scope"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently delete your data and deactivate your account."])},
    "delete_account_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue deleting your account. If this persists please contact your instance administrator."])},
    "delete_account_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your password in the input below to confirm account deletion."])},
    "disable_sticky_headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't stick column headers to top of the screen"])},
    "discoverable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow discovery of this account in search results and other services"])},
    "domain_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
    "download_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "email_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language for receiving emails from the server"])},
    "emoji_reactions_on_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show emoji reactions on timeline"])},
    "enable_web_push_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable web push notifications"])},
    "enter_current_password_to_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your current password to confirm your identity"])},
    "expert_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced"])},
    "expire_posts_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete posts after a set amount of days"])},
    "expire_posts_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of days"])},
    "export_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save preset"])},
    "file_export_import": {
      "backup_restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings backup"])},
      "backup_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup settings to file"])},
      "backup_settings_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup settings and theme to file"])},
      "errors": {
        "file_slightly_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File minor version is different, some settings might not load"])},
        "file_too_new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Incompatible major version: ", _interpolate(_named("fileMajor")), ", this PleromaFE (settings ver ", _interpolate(_named("feMajor")), ") is too old to handle it"])},
        "file_too_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Incompatible major version: ", _interpolate(_named("fileMajor")), ", file version is too old and not supported (min. set. ver. ", _interpolate(_named("feMajor")), ")"])},
        "invalid_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected file is not a supported Pleroma settings backup. No changes were made."])}
      },
      "restore_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore settings from file"])}
    },
    "filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering"])},
    "filtering_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All posts containing these words will be muted, one per line"])},
    "follow_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow export"])},
    "follow_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your follows to a csv file"])},
    "follow_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow import"])},
    "follow_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing followers"])},
    "follows_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows imported! Processing them will take a while."])},
    "foreground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreground"])},
    "fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fun"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "greentext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meme arrows"])},
    "hide_all_muted_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide muted posts"])},
    "hide_attachments_in_convo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachments in conversations"])},
    "hide_attachments_in_tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachments in timeline"])},
    "hide_bot_indication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide bot indication in posts"])},
    "hide_favorites_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show list of my favorites (people still get notified)"])},
    "hide_filtered_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide all filtered posts"])},
    "hide_followers_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show follower count"])},
    "hide_followers_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show who's following me"])},
    "hide_follows_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show follow count"])},
    "hide_follows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show who I'm following"])},
    "hide_isp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance-specific panel"])},
    "hide_list_aliases_error_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "hide_media_previews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide media previews"])},
    "hide_muted_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide posts of muted users"])},
    "hide_muted_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide muted threads"])},
    "hide_post_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide post statistics (e.g. the number of favorites)"])},
    "hide_shoutbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance shoutbox"])},
    "hide_site_favicon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance favicon in top panel"])},
    "hide_site_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance name in top panel"])},
    "hide_threads_with_blocked_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide threads mentioning blocked users"])},
    "hide_user_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide user statistics (e.g. the number of followers)"])},
    "hide_wallpaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance wallpaper"])},
    "hide_wordfiltered_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide word-filtered posts"])},
    "import_blocks_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import blocks from a csv file"])},
    "import_followers_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import follows from a csv file"])},
    "import_mutes_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import mutes from a csv file"])},
    "import_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load preset"])},
    "inputRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
    "instance_default": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(default: ", _interpolate(_named("value")), ")"])},
    "instance_default_simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(default)"])},
    "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
    "interfaceLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface language"])},
    "invalid_theme_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected file is not a supported Pleroma theme. No changes to your theme were made."])},
    "limited_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable in your browser"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "list_aliases_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error fetching aliases: ", _interpolate(_named("error"))])},
    "list_backups_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error fetching backup list: ", _interpolate(_named("error"))])},
    "lock_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrict your account to approved followers only"])},
    "loop_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop videos"])},
    "loop_video_silent_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop only videos without sound (i.e. Mastodon's \"gifs\")"])},
    "mascot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastodon FE Mascot"])},
    "max_depth_in_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of levels in thread to display by default"])},
    "max_thumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of thumbnails per post (empty = no limit)"])},
    "mention_link_bolden_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight mention of you when you are mentioned"])},
    "mention_link_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display mention links"])},
    "mention_link_display_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["always as full names (e.g. ", "@", "foo", "@", "example.org)"])},
    "mention_link_display_full_for_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as full names only for remote users (e.g. ", "@", "foo", "@", "example.org)"])},
    "mention_link_display_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["always as short names (e.g. ", "@", "foo)"])},
    "mention_link_fade_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fade domains (e.g. ", "@", "example.org in ", "@", "foo", "@", "example.org)"])},
    "mention_link_show_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user avatar beside the link"])},
    "mention_link_show_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show full user names as tooltip for remote users"])},
    "mention_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention links"])},
    "mfa": {
      "authentication_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication methods"])},
      "confirm_and_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm & enable OTP"])},
      "generate_new_recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new recovery codes"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
      "recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery codes."])},
      "recovery_codes_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the codes down or save them somewhere secure - otherwise you won't see them again. If you lose access to your 2FA app and recovery codes you'll be locked out of your account."])},
      "scan": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using your two-factor app, scan this QR code or enter text key:"])},
        "secret_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan"])}
      },
      "setup_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup OTP"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor Authentication"])},
      "verify": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable two-factor authentication, enter the code from your two-factor app:"])}
      },
      "wait_pre_setup_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presetting OTP"])},
      "waiting_a_recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving backup codes…"])},
      "warning_of_generate_new_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you generate new recovery codes, your old codes won’t work anymore."])}
    },
    "minimal_scopes_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize post scope selection options"])},
    "more_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More settings"])},
    "move_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move account"])},
    "move_account_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error moving account: ", _interpolate(_named("error"))])},
    "move_account_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to move this account somewhere else, you must go to your target account and add an alias pointing here."])},
    "move_account_target": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Target account (e.g. ", _interpolate(_named("example")), ")"])},
    "moved_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is moved."])},
    "mute_bot_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute bot posts"])},
    "mute_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute export"])},
    "mute_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your mutes to a csv file"])},
    "mute_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute import"])},
    "mute_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing mutes"])},
    "mutes_and_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes and Blocks"])},
    "mutes_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes imported! Processing them will take a while."])},
    "mutes_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name & bio"])},
    "new_alias_target": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add a new alias (e.g. ", _interpolate(_named("example")), ")"])},
    "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "no_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No blocks"])},
    "no_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mutes"])},
    "no_rich_text_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strip rich text formatting from all posts"])},
    "notification_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking a user stops all notifications as well as unsubscribes them."])},
    "notification_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To stop receiving notifications from a specific user, use a mute."])},
    "notification_setting_block_from_strangers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block notifications from users who you do not follow"])},
    "notification_setting_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "notification_setting_hide_if_cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the contents of push notifications if under a Content Warning"])},
    "notification_setting_hide_notification_contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the sender and contents of push notifications"])},
    "notification_setting_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "notification_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of notifications to show"])},
    "notification_visibility_emoji_reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactions"])},
    "notification_visibility_follows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows"])},
    "notification_visibility_likes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "notification_visibility_mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "notification_visibility_moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Migrates"])},
    "notification_visibility_polls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends of polls you voted in"])},
    "notification_visibility_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "nsfw_clickthrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide sensitive/NSFW media"])},
    "oauth_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth tokens"])},
    "pad_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad emoji with spaces when adding from picker"])},
    "panelRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panels"])},
    "pause_on_unfocused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause when tab is not focused"])},
    "play_videos_in_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play videos in a popup frame"])},
    "post_look_feel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts Look & Feel"])},
    "post_status_content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default post content type"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
    "preload_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preload images"])},
    "presets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presets"])},
    "profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile background"])},
    "profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile banner"])},
    "profile_fields": {
      "add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add field"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile metadata"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])}
    },
    "profile_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "radii_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up interface edge rounding (in pixels)"])},
    "refresh_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh token"])},
    "remove_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this alias"])},
    "remove_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "render_mfm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Render Misskey Markdown"])},
    "render_mfm_on_hover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause MFM animations until post hover"])},
    "replies_in_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies in timeline"])},
    "reply_visibility_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all replies"])},
    "reply_visibility_following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies directed at me or users I'm following"])},
    "reply_visibility_following_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show replies to my follows"])},
    "reply_visibility_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies directed at me"])},
    "reply_visibility_self_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show replies to self only"])},
    "reset_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset avatar"])},
    "reset_avatar_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the avatar?"])},
    "reset_background_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the background?"])},
    "reset_banner_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the banner?"])},
    "reset_profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset profile background"])},
    "reset_profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset profile banner"])},
    "revoke_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
    "right_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse order of columns"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "saving_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving settings"])},
    "saving_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings saved"])},
    "scope_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy scope when replying (DMs are always copied)"])},
    "search_user_to_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search whom you want to block"])},
    "search_user_to_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search whom you want to mute"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "security_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "sensitive_by_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark posts as sensitive by default"])},
    "sensitive_if_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically mark images as sensitive if a content warning is specified"])},
    "set_new_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new avatar"])},
    "set_new_mascot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new mascot"])},
    "set_new_profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new profile background"])},
    "set_new_profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new profile banner"])},
    "setting_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting is different from default"])},
    "setting_server_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This setting is tied to your profile and affects all sessions and clients"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "settings_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings Profiles"])},
    "settings_profile_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new profile"])},
    "settings_profile_creation_new_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "settings_profile_creation_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "settings_profile_currently": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Currently using ", _interpolate(_named("name")), " (version: ", _interpolate(_named("version")), ")"])},
    "settings_profile_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "settings_profile_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this profile?"])},
    "settings_profile_force_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize"])},
    "settings_profile_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In use"])},
    "settings_profile_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])},
    "settings_profiles_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload settings profiles"])},
    "settings_profiles_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all settings profiles"])},
    "settings_profiles_unshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide all settings profiles"])},
    "show_admin_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show \"Admin\" badge in my profile"])},
    "show_moderator_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show \"Moderator\" badge in my profile"])},
    "show_nav_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show extra navigation shortcuts in top panel"])},
    "show_panel_nav_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show timeline navigation shortcuts at the top of the panel"])},
    "show_scrollbars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show side column's scrollbars"])},
    "show_wider_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show wider gap between top panel shortcuts"])},
    "show_yous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show (You)s"])},
    "stop_gifs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause animated images until you hover on them"])},
    "streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically show new posts when scrolled to the top"])},
    "style": {
      "advanced_colors": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert background"])},
        "alert_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "alert_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "alert_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge background"])},
        "badge_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
        "borders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borders"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
        "chat": {
          "border": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Border"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])}
        },
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "faint_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faded text"])},
        "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlighted elements"])},
        "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
        "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
        "panel_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel header"])},
        "poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll graph"])},
        "popover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips, menus, popovers"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts/User bios"])},
        "pressed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressed"])},
        "selectedMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected menu item"])},
        "selectedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected post"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabs"])},
        "toggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggled"])},
        "top_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top bar"])},
        "underlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underlay"])},
        "wallpaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallpaper"])}
      },
      "common": {
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
        "contrast": {
          "context": {
            "18pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for large (18pt+) text"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for text"])}
          },
          "hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contrast ratio is ", _interpolate(_named("ratio")), ", it ", _interpolate(_named("level")), " ", _interpolate(_named("context"))])},
          "level": {
            "aa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meets Level AA guideline (minimal)"])},
            "aaa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meets Level AAA guideline (recommended)"])},
            "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doesn't meet any accessibility guidelines"])}
          }
        },
        "opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opacity"])}
      },
      "common_colors": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
        "foreground_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See \"Advanced\" tab for more detailed control"])},
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common colors"])},
        "rgbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons, accents, badges"])}
      },
      "fonts": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonts"])},
        "components": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
          "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
          "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post text"])},
          "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monospaced text in a post (rich text)"])}
        },
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font name"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select font to use for elements of UI. For \"custom\" you have to enter exact font name as it appears in system."])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size (in px)"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (boldness)"])}
      },
      "preview": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
        "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have skimmed over terms and conditions"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example error"])},
        "faint_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helpful manual"])},
        "fine_print": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Read our ", _interpolate(_list(0)), " to learn nothing useful!"])},
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "header_faint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is fine"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just arrived at Luna Nova Academy"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a nice lil' link"])},
        "mono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A bunch of more ", _interpolate(_list(0)), " and ", _interpolate(_list(1))])}
      },
      "radii": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roundness"])}
      },
      "shadows": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow and lighting"])},
        "blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blur"])},
        "component": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Component"])},
        "components": {
          "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User avatar (in profile view)"])},
          "avatarStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User avatar (in post display)"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
          "buttonHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (hover)"])},
          "buttonPressed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (pressed)"])},
          "buttonPressedHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (pressed+hover)"])},
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input field"])},
          "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
          "panelHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel header"])},
          "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popups and tooltips"])},
          "topBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top bar"])}
        },
        "filter_hint": {
          "always_drop_shadow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Warning, this shadow always uses ", _interpolate(_list(0)), " when browser supports it."])},
          "avatar_inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that combining both inset and non-inset shadows on avatars might give unexpected results with transparent avatars."])},
          "drop_shadow_syntax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " does not support ", _interpolate(_list(1)), " parameter and ", _interpolate(_list(2)), " keyword."])},
          "inset_classic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Inset shadows will be using ", _interpolate(_list(0))])},
          "spread_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadows with spread > 0 will appear as if it was set to zero"])}
        },
        "hintV3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["For shadows you can also use the ", _interpolate(_list(0)), " notation to use other color slot."])},
        "inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inset"])},
        "override": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override"])},
        "shadow_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shadow #", _interpolate(_named("value"))])},
        "spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread"])}
      },
      "switcher": {
        "clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
        "clear_opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear opacity"])},
        "help": {
          "fe_downgraded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE's version rolled back."])},
          "fe_upgraded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE's theme engine upgraded after version update."])},
          "future_version_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made in newer version of FE."])},
          "migration_napshot_gone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For whatever reason snapshot was missing, some stuff could look different than you remember."])},
          "migration_snapshot_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just to be safe, theme snapshot loaded. You can try loading theme data."])},
          "older_version_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made in older version of FE."])},
          "snapshot_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No theme snapshot was in the file so it could look different than originally envisioned."])},
          "snapshot_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme snapshot is loaded, so all values are overriden. You can load theme's actual data instead."])},
          "snapshot_source_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions conflict: most likely FE was rolled back and updated again, if you changed theme using older version of FE you most likely want to use old version, otherwise use new version."])},
          "upgraded_from_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE has been upgraded, theme could look a little bit different than you remember."])},
          "v2_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made for older FE. We try to maximize compatibility but there still could be inconsistencies."])}
        },
        "keep_as_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep as is"])},
        "keep_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep colors"])},
        "keep_fonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep fonts"])},
        "keep_opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep opacity"])},
        "keep_roundness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep roundness"])},
        "keep_shadows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep shadows"])},
        "load_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load theme"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "save_load_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Keep\" options preserve currently set options when selecting or loading themes, it also stores said options when exporting a theme. When all checkboxes unset, exporting theme will save everything."])},
        "use_snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old version"])},
        "use_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version"])}
      }
    },
    "subject_input_always_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always show content warning field"])},
    "subject_line_behavior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy content warning when replying"])},
    "subject_line_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like email: \"re: warning\""])},
    "subject_line_mastodon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like mastodon: copy as is"])},
    "subject_line_noop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not copy"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
    "theme_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use hex color codes (#rrggbb) to customize your color theme."])},
    "theme_help_v2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also override certain component's colors and opacity by toggling the checkbox, use \"Clear all\" button to clear all overrides."])},
    "theme_help_v2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons underneath some entries are background/text contrast indicators, hover over for detailed info. Please keep in mind that when using transparency contrast indicators show the worst possible case."])},
    "third_column_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When there's enough space, show third column containing"])},
    "third_column_mode_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show third column at all"])},
    "third_column_mode_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications column"])},
    "third_column_mode_postform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main post form and navigation"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
    "tooltipRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips/alerts"])},
    "translation_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Translation Language"])},
    "tree_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display extra buttons to open and close reply chains in threads"])},
    "tree_fade_ancestors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display ancestors of the current post in faint text"])},
    "type_domains_to_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search domains to mute"])},
    "upload_a_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a photo"])},
    "useStreamingApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive posts and notifications real-time"])},
    "useStreamingApiWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's cool use it. If it breaks refresh I guess?"])},
    "use_at_icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display ", "@", " symbol as an icon instead of text"])},
    "use_contain_fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't crop the attachment in thumbnails"])},
    "use_one_click_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open NSFW attachments with just one click"])},
    "user_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "user_profile_default_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Tab on User Profile"])},
    "user_profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profiles"])},
    "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])},
    "user_accepts_direct_messages_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept DMs From"])},
    "user_accepts_direct_messages_from_everybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everybody"])},
    "user_accepts_direct_messages_from_nobody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nobody"])},
    "user_accepts_direct_messages_from_people_i_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People I follow"])},
    "valid_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "values": {
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
    },
    "version": {
      "backend_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend version"])},
      "frontend_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend version"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
    },
    "virtual_scrolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize timeline rendering"])},
    "use_blurhash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use blurhashes for NSFW thumbnails"])},
    "word_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word filter"])},
    "wordfilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordfilter"])}
  },
  "settings_profile": {
    "creating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creating new setting profile \"", _interpolate(_named("profile")), "\"..."])},
    "synchronization_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not synchronize settings: ", _interpolate(_named("err"))])},
    "synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronized settings!"])},
    "synchronizing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Synchronizing setting profile \"", _interpolate(_named("profile")), "\"..."])}
  },
  "status": {
    "ancestor_follow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["See ", _interpolate(_named("numReplies")), " other reply under this post"]), _normalize(["See ", _interpolate(_named("numReplies")), " other replies under this post"])])},
    "ancestor_follow_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "attachment_stop_flash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Flash player"])},
    "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
    "collapse_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse attachments"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link to post"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete post"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this post?"])},
    "delete_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])},
    "delete_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, keep it"])},
    "delete_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "edit_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit History"])},
    "edit_history_modal_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edited ", _interpolate(_named("historyCount")), " time"]), _normalize(["Edited ", _interpolate(_named("historyCount")), " times"])])},
    "edited_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edited ", _interpolate(_named("time"))])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
    "external_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External source"])},
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "hide_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachment"])},
    "hide_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide content"])},
    "hide_full_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide full content warning"])},
    "many_attachments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Post has ", _interpolate(_named("number")), " attachment"]), _normalize(["Post has ", _interpolate(_named("number")), " attachments"])])},
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "move_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift attachment right"])},
    "move_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift attachment left"])},
    "mute_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute conversation"])},
    "nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NSFW"])},
    "open_gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open gallery"])},
    "override_translation_source_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override source language"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin on profile"])},
    "pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinned"])},
    "plus_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+", _interpolate(_named("number")), " more"])},
    "redraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete & redraft"])},
    "redraft_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete and redraft this post? Interactions to the original post will not be preserved."])},
    "redraft_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete and redraft"])},
    "redraft_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, keep the original"])},
    "redraft_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm delete & redraft"])},
    "remove_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove attachment"])},
    "repeat_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to repeat this post?"])},
    "repeat_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, repeat it"])},
    "repeat_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, don't repeat"])},
    "repeat_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm repeat"])},
    "repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats"])},
    "replies_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies:"])},
    "replies_list_with_others": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["View ", _interpolate(_named("numReplies")), " more reply"]), _normalize(["View ", _interpolate(_named("numReplies")), " more replies"])])},
    "reply_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to"])},
    "show_all_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all attachments"])},
    "show_all_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show full conversation (", _interpolate(_named("numStatus")), " other post)"]), _normalize(["Show full conversation (", _interpolate(_named("numStatus")), " other posts)"])])},
    "show_all_conversation_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "show_attachment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview description (open attachment for full description)"])},
    "show_attachment_in_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show attachment in a window"])},
    "show_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show content"])},
    "show_full_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show full content warning"])},
    "show_only_conversation_under_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies to this post"])},
    "status_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post was deleted"])},
    "status_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post unavailable"])},
    "thread_follow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["View ", _interpolate(_named("numStatus")), " more reply"]), _normalize(["View ", _interpolate(_named("numStatus")), " more replies"])])},
    "thread_follow_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "thread_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide this thread"])},
    "thread_muted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread muted"])},
    "thread_muted_and_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", has words:"])},
    "thread_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this thread"])},
    "thread_show_full": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show ", _interpolate(_named("numStatus")), " reply"]), _normalize(["Show all ", _interpolate(_named("numStatus")), " replies"])])},
    "thread_show_full_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate"])},
    "translated_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Translated from ", _interpolate(_named("language"))])},
    "unbookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbookmark"])},
    "unmute_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute conversation"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpin from profile"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(You)"])}
  },
  "time": {
    "in_future": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["in ", _interpolate(_list(0))])},
    "in_past": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ago"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["just now"])},
    "now_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])},
    "unit": {
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " day"]), _normalize([_interpolate(_list(0)), " days"])])},
      "days_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "d"])},
      "hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " hour"]), _normalize([_interpolate(_list(0)), " hours"])])},
      "hours_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "h"])},
      "minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " minute"]), _normalize([_interpolate(_list(0)), " minutes"])])},
      "minutes_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "min"])},
      "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " month"]), _normalize([_interpolate(_list(0)), " months"])])},
      "months_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "mo"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " second"]), _normalize([_interpolate(_list(0)), " seconds"])])},
      "seconds_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s"])},
      "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " week"]), _normalize([_interpolate(_list(0)), " weeks"])])},
      "weeks_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "w"])},
      "years": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " year"]), _normalize([_interpolate(_list(0)), " years"])])},
      "years_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "y"])}
    }
  },
  "timeline": {
    "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
    "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error fetching timeline: ", _interpolate(_list(0))])},
    "follow_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow hashtag"])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older posts"])},
    "no_more_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more posts"])},
    "no_retweet_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post is marked as followers-only or direct and cannot be repeated or quoted"])},
    "no_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No posts"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
    "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeated"])},
    "show_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show new"])},
    "socket_broke": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Realtime connection lost: CloseEvent code ", _interpolate(_list(0))])},
    "socket_reconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtime connection established"])},
    "follow_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow hashtag"])},
    "unfollow_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow hashtag"])},
    "up_to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up-to-date"])}
  },
  "toast": {
    "no_translation_target_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No translation target language set - this may fail. Please set a target language in your settings."])}
  },
  "tool_tip": {
    "accept_follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept follow request"])},
    "add_reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Reaction"])},
    "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "media_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload media"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
    "reject_follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject follow request"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])}
  },
  "upload": {
    "error": {
      "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed."])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again later"])},
      "file_too_big": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File too big [", _interpolate(_named("filesize")), _interpolate(_named("filesizeunit")), " / ", _interpolate(_named("allowedsize")), _interpolate(_named("allowedsizeunit")), "]"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Upload failed: ", _interpolate(_list(0))])}
    },
    "file_size_units": {
      "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      "GiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GiB"])},
      "KiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KiB"])},
      "MiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MiB"])},
      "TiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiB"])}
    }
  },
  "user_card": {
    "admin_menu": {
      "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
      "deactivate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate account"])},
      "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
      "delete_user_data_and_deactivate_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the data from this account and deactivate it. Are you absolutely sure?"])},
      "disable_any_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow following user at all"])},
      "disable_remote_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow following user from remote instances"])},
      "force_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all posts as NSFW"])},
      "force_unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force posts to be unlisted"])},
      "grant_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Admin"])},
      "grant_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Moderator"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
      "quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow user posts from federating"])},
      "revoke_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Admin"])},
      "revoke_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Moderator"])},
      "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force posts to be followers-only"])},
      "strip_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove media from posts"])}
    },
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "approve_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to let this user follow you?"])},
    "approve_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, accept"])},
    "approve_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
    "approve_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve follow request"])},
    "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "block_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to block ", _interpolate(_named("user")), "?"])},
    "block_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, block"])},
    "block_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, don't block"])},
    "block_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user"])},
    "block_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking…"])},
    "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked!"])},
    "blocks_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks you!"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
    "deny_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to deny this user's follow request?"])},
    "deny_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, deny"])},
    "deny_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
    "deny_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny follow request"])},
    "domain_muted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock domain"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
    "follow_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "follow_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting…"])},
    "follow_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request sent!"])},
    "follow_unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
    "followees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers"])},
    "followed_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followed hashtags"])},
    "followed_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followed users"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following!"])},
    "follows_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows you!"])},
    "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden"])},
    "hide_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide repeats"])},
    "highlight": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No highlight"])},
      "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side stripe"])},
      "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solid bg"])},
      "striped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Striped bg"])}
    },
    "its_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's you!"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
    "mute_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to mute ", _interpolate(_named("user")), "?"])},
    "mute_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, mute"])},
    "mute_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, don't mute"])},
    "mute_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute user"])},
    "mute_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block domain"])},
    "mute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muting…"])},
    "muted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muted"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private note"])},
    "per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per day"])},
    "remote_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote follow"])},
    "remove_follower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove follower"])},
    "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Replies"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "requested_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has requested to follow you"])},
    "show_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show repeats"])},
    "statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
    "unblock_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblocking…"])},
    "unfollow_confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to unfollow ", _interpolate(_named("user")), "?"])},
    "unfollow_confirm_accept_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, unfollow"])},
    "unfollow_confirm_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, don't unfollow"])},
    "unfollow_confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow user"])},
    "not_following_any_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not following any hashtags"])},
    "follow_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow hashtag"])},
    "unfollow_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow hashtag"])},
    "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute"])},
    "unmute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmuting…"])},
    "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])}
  },
  "user_profile": {
    "profile_does_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this profile does not exist."])},
    "profile_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there was an error loading this profile."])},
    "timeline_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User timeline"])},
    "field_validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Verified"])}
  },
  "user_reporting": {
    "add_comment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report will be sent to your instance moderators. You can provide an explanation of why you are reporting this account below:"])},
    "additional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional comments"])},
    "forward_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account is from another server. Send a copy of the report there as well?"])},
    "forward_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Forward to ", _interpolate(_list(0))])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing your request."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reporting ", _interpolate(_list(0))])}
  },
  "who_to_follow": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])}
  }
}